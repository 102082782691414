import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({ field, errors, label, touched, type, autoComplete, borderBottom, filled, placeholder }) => {
  const s = require('./InputField.module.scss');
  const { id, value } = field;

  return (
    <div className={`${touched || value ? s.ContainerOpen : s.Container} && ${filled ? s.filled : ""}`}>
      <input {...field} placeholder={placeholder} className={`${s.Input} && ${borderBottom ? s.borderBottom : ""}`} type={type} autoComplete={autoComplete} id={label} />
      <label htmlFor={label} className={s.Label}>{label}</label>
      {touched && errors && <span className={s.error}>{errors}</span>}
    </div>
  );
};

InputField.propTypes = {
  field: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string
};

export default InputField;
